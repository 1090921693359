import React from 'react'
import GoogleMapReact from 'google-map-react'
import PinIcon from '../../assets/icons/PinIcon.svg'
interface MapDisplayProps {
    alwaysCenter: boolean,
    width?: number | string,
    height?: number | string,
    defaults?: {
        lat: number,
        lng: number,
        address?: string,
        zoom?: number
    },
    location?: {
        lat: number,
        lng: number,
        address?: string
    }
    onChange?: (location:any) => any
}
const MapDisplay: React.FC<MapDisplayProps> = (props) => {

    const handleChange = (event: any) => {
        console.log('event', event?.center);
        if (props.onChange) {
            props.onChange(event?.center)
        }
    }
    return (
        <div style={{ height: props.height || '80vh', width: props.width || '100%', position: 'relative' }}>
            <img style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 2500,
                width: '35px'
            }} src={PinIcon} alt="Pin Icon" />
            <GoogleMapReact
                defaultCenter={props.defaults || { lat: 13.6970213, lng: 100.6083957 }}
                defaultZoom={props.defaults?.zoom || 15}
                options={{ fullscreenControl: false }}
                onChange={handleChange}
            >
            </GoogleMapReact>
        </div>
    )
}

export default MapDisplay