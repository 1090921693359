import React from 'react'
import ArrowUp from '../../assets/icons/ArrowUp.svg'
import ArrowDown from '../../assets/icons/ArrowDown.svg'

interface MapInputProps {
    isExpended: boolean,
    onToggleExpend: () => any,
    default?: string
}
const MapInput: React.FC<MapInputProps> = (props) => {

    return (
        <div style={{ padding: '16px', position: 'relative', marginTop: '-14px', backgroundColor: 'white', borderTopRightRadius: '16px', borderTopLeftRadius: '16px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <img alt='arrow-icons' onClick={() => props.onToggleExpend()} width={'40px'} src={props.isExpended ? ArrowDown : ArrowUp} />

            </div>
            {!props.isExpended && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><span>ค้นหาสถานที่ใกล้เคียง</span></div>}
        </div>
    )
}

export default MapInput