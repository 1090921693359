import React from 'react';
import '../../assets/css/footer.css'
import HomeIcon from '../../assets/icons/HomeIcon.svg'
import CartIcon from '../../assets/icons/CartIcon.svg'
import OrderIcon from '../../assets/icons/OrderIcon.svg'
import ProfileIcon from '../../assets/icons/ProfileIcon.svg'

const Footer: React.FC = () => {
    const isActive = (path: string): boolean => {
        return path === '/'
    }
    return (
        <footer className="footer">
            <button className={`footer-button ${isActive('/') ? 'active' : ''}`} onClick={() => window.location.href = '/'}>
                <img src={HomeIcon} alt="Home" className="icon" />
                หน้าหลัก
            </button>
            <button className={`footer-button ${isActive('/cart') ? 'active' : ''}`} onClick={() => window.location.href = '/cart'}>
                <img src={CartIcon} alt="Cart" className="icon" />
                รถเข็น
            </button>
            <button className={`footer-button ${isActive('/orders') ? 'active' : ''}`} onClick={() => window.location.href = '/orders'}>
                <img src={OrderIcon} alt="Orders" className="icon" />
                ออเดอร์
            </button>
            <button className={`footer-button ${isActive('/profile') ? 'active' : ''}`} onClick={() => window.location.href = '/profile'}>
                <img src={ProfileIcon} alt="Profile" className="icon" />
                โปร์ไฟล์
            </button>
        </footer>
    );
}

export default Footer;
