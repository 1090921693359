import React, { useState } from 'react'
import MapDisplay from '../../components/map-display/MapDisplay'
import MapInput from '../../components/map-input/MapInput'
interface locationInf {
    lat: number,
    lng: number,
    address?: string
}
const MapSelector = () => {
    const [isExpended, setIsExpended] = useState<boolean>(false)
    const [location, setLocation] = useState<locationInf | null>()
    const onToggleExpend = () => {
        setIsExpended(!isExpended)
    }
    const handleChangeLocation = (event: locationInf) => {
        console.log('event',event);
        
        setLocation(event)
    }
    return (
        <div>
            <MapDisplay onChange={handleChangeLocation} height={isExpended ? '40vh' : ''} alwaysCenter={true} />
            <MapInput
                
                isExpended={isExpended}
                onToggleExpend={() => onToggleExpend()}
            />
        </div>
    )
}

export default MapSelector